import { Component } from 'preact'
import cx from 'classnames'

import { birmanize, formatPrice } from 'lib/textHelper'
import { decorateSchoolPrice } from '../school/schoolHelpers'
import { Caption, TextNode } from '../../textNodes'
import { moscowifyLocal } from '../../calendar/timezoneUtils'

import Price from '../../price/price'
import Pricing from '../../price/pricing'

const MAX_SEATS_LEFT = 5

export default class NewPaybarCoursePricing extends Component {
  get couponValidUntilInitial() {
    return this.props.prices.couponValidUntil * 1000
  }

  get couponEndsAt() {
    return new Date(this.couponValidUntilInitial).getTime()
  }

  get couponWillChangeAt() {
    return new Date(this.couponValidUntilInitial).getTime() + 1000
  }

  get willChangeAt() {
    return new Date(this.props.prices.willChangeAt)
  }

  get isCouponApplied() {
    return this.props.prices.isCouponApplied
  }

  get seatsLeft() {
    return this.props.seatsLeft || 0
  }

  get shouldMentionSeatsLeft() {
    return this.seatsLeft > 0 && this.seatsLeft <= MAX_SEATS_LEFT
  }

  get singupDeadline() {
    const date = moscowifyLocal(new Date(this.props.startsAt))
    date.setDate(date.getDate() - 1)

    return birmanize(date, { format: 'weekDayAndDate' }).toLowerCase()
  }

  get currentPricing() {
    const { prices, type, isStudent } = this.props

    return isStudent[type] ? prices.student : prices
  }

  get hasNextPrice() {
    const { prices } = this.props

    return prices.nextOneTime && prices.nextOneTime !== prices.oneTime && prices.oneTime !== 1
  }

  get currentPrice() {
    const { type } = this.props
    const participantPrices = this.currentPricing
    const price = type === 'company' ? participantPrices.companyOneTime : participantPrices.oneTime

    return decorateSchoolPrice(formatPrice(price))
  }

  get currentPriceLabel() {
    const { type } = this.props
    const participantPrices = this.currentPricing
    const max = type === 'company' ? participantPrices.companyMax : participantPrices.max

    if (this.isCouponApplied) return `<s>${max}</s>`
    if (!this.hasNextPrice) return null

    return `<s>${max}</s>`
  }

  get nextPrice() {
    const { type } = this.props
    const prices = this.currentPricing
    const { nextOneTime, nextCompanyOneTime, previousOneTime, previousCompanyOneTime } = prices

    let price = type === 'company' ? nextCompanyOneTime : nextOneTime

    if (this.isCouponApplied && this.couponWillChangeAt < this.willChangeAt) {
      price = type === 'company' ? previousCompanyOneTime : previousOneTime
    }

    return decorateSchoolPrice(formatPrice(price))
  }

  get nextPriceLabel() {
    const dateOfChange = this.isCouponApplied ? this.couponWillChangeAt : this.willChangeAt

    return birmanize(dateOfChange, { format: 'sinceWeekDayLowerCaseAndDate' })
  }

  get lastSignupDateAndSeatsLeft() {
    const lastSignupDate = I18n.t('newPaybar.course.lastSignupDate', { endsAt: this.singupDeadline })
    const seatsLeft = I18n.t('newPaybar.course.seatsLeft', { count: this.seatsLeft })

    if (this.shouldMentionSeatsLeft) return [lastSignupDate, seatsLeft].join('. ')

    return lastSignupDate
  }

  get couponSignupMotivation() {
    const couponEndsAt = birmanize(this.couponEndsAt, { format: 'dateAndTime' })
    const couponSignupDate = I18n.t('newPaybar.course.couponSignupMotivation', { couponEndsAt })

    return [couponSignupDate, this.lastSignupDateAndSeatsLeft].join('<br>')
  }

  get nextPriceSignupMotivation() {
    const lastSignupDate = I18n.t('newPaybar.course.signupMotivation', { endsAt: this.singupDeadline })

    return [lastSignupDate, this.lastSignupDateAndSeatsLeft].join('<br>')
  }

  get motivation() {
    if (this.isCouponApplied) return this.couponSignupMotivation
    if (this.hasNextPrice) return this.nextPriceSignupMotivation

    return this.lastSignupDateAndSeatsLeft
  }

  get className() {
    return cx('newPaybarPricing', {
      is__couponApplied: this.isCouponApplied,
      has__nextPrice: this.hasNextPrice,
    })
  }

  render() {
    return (
      <div className={ this.className }>
        <Pricing>
          <Price
            label={ this.currentPriceLabel }
            amount={ this.currentPrice }
            smallUnits
            highlighted={ this.hasNextPrice || this.isCouponApplied }
            hasCompactUnitsView />

          { (this.hasNextPrice || this.isCouponApplied) &&
            <Price
              label={ this.nextPriceLabel }
              amount={ this.nextPrice }
              smallUnits
              hasCompactUnitsView />
          }
        </Pricing>

        { this.motivation &&
          <TextNode
            className="newPaybarPricing-info module device device__mobile"
            html={ this.motivation } />
        }

        { this.motivation &&
          <Caption
            className="newPaybarPricing-info module device device__desktop device__laptop"
            html={ this.motivation } />
        }
      </div>
    )
  }
}

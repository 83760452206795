const throttle = require('throttle')

const VideoPlayerState = require('lib/videoPlayerState')

const SAVE_PROGRESS_RATE_MS = 1000

const MIN_POSSIBLE_QUALITY = '720p'
const AUTO_QUALITY = 'auto'

const shouldLimitQuality = () => /^\/school\//.test(window.application.pageId)
const nope = e => console.error(e) // eslint-disable-line no-console

class VimeoVideo {
  constructor(el, options = {}) {
    this.el = el
    this.id = el.getAttribute('data-vimeo-id')
    this.startTime = el.getAttribute('data-vimeo-start')
    this.playerState = new VideoPlayerState({ id: `vi-${this.id}` })
    this.shouldSaveProgress = options.shouldSaveProgress
    this.shouldSavePlaybackRate = options.shouldSavePlaybackRate

    let startTime = (this.shouldSaveProgress ? this.playerState.progress : null) || this.startTime
    const quality = shouldLimitQuality() ? MIN_POSSIBLE_QUALITY : AUTO_QUALITY

    if (this.id.includes('/')) {
      const idWithHash = this.id.replace('/', '?h=')
      el.removeAttribute('data-vimeo-id')
      el.setAttribute('data-vimeo-url', `https://player.vimeo.com/video/${idWithHash}`)
    }

    this.player = new Vimeo.Player(el, { start_time: startTime, quality })

    this.player.ready().then(this.setup.bind(this))
  }

  setup() {
    this.el.addEventListener('playNow', this.player.play.bind(this.player))

    if (this.shouldSavePlaybackRate) {
      this.playbackRate = this.playerState.playbackRate
      this.player.setPlaybackRate(this.playbackRate).catch(nope)

      this.player.on('playbackratechange', this.savePlaybackRate.bind(this))

      document.addEventListener('playbackRateChange', this.onPlaybackRateChange.bind(this))
    }

    if (this.shouldSaveProgress) {
      this.player.on('timeupdate', throttle(SAVE_PROGRESS_RATE_MS, this.saveProgress.bind(this)))
    }
  }

  onPlaybackRateChange(e) {
    const { playbackRate, id } = e.detail

    if (this.id !== id && playbackRate !== this.playbackRate) {
      this.player.setPlaybackRate(playbackRate).catch(nope)
    }
  }

  saveProgress({ seconds, duration }) {
    this.playerState.saveProgress({ seconds, duration })
  }

  savePlaybackRate({ playbackRate }) {
    this.playbackRate = playbackRate
    this.playerState.savePlaybackRate(playbackRate)
    this.syncPlaybackRates()
  }

  syncPlaybackRates() {
    const { playbackRate, id } = this
    const event = new CustomEvent('playbackRateChange', { detail: { playbackRate, id } })

    document.dispatchEvent(event)
  }
}

module.exports = VimeoVideo
